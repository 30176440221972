import * as React from "react";
import TemplatePage1 from "@components/TemplatePage/TemplatePage";
import styled from "styled-components";
import img1 from '@images/definiciones/efficiency.png';
import img2 from '@images/definiciones/equity.png';
import img3 from '@images/definiciones/research.png';
import img4 from '@images/definiciones/shield.png';
import img5 from '@images/definiciones/time-management.png';
import img6 from '@images/definiciones/ux-design.png';
import adorno from "@images/adorno/adorno4.png";

const Wrapper = styled.div`
color: white;
padding-top: 1rem;
display: flex;
flex-direction: column;
align-items: center;
text-align: justify;
`;

const CardContainer = styled.div`
display: flex;
flex-direction: column;
align-items: center;
width: 100%;
margin-bottom: 2rem;
@media (min-width: 768px){
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: center;
  align-items: stretch;
}
`;

const Card = styled.div`
width: 100%;
display: flex;
flex-direction: column;
align-items: center;
padding: 1rem 0.5rem 1rem 0.5rem;
margin: 0.5rem;
color:white;

img{
  width: 80px;
  height: 80px;
}
h1{
  font-size: 1rem;
  text-align: center;
}
p{
  text-align: center;
  font-size: 0.8rem;
}

@media (min-width: 768px){
  width: 40%;
}

@media (min-width: 1024px){
  width: 30%;
}

:hover{
  img{
    animation: animateHeart 1.2s infinite;
  }
}

@keyframes animateHeart {
  0% {
    transform: scale(0.8);
  }
  5% {
    transform: scale(0.9);
  }
  10% {
    transform: scale(0.8);
  }
  15% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(0.8);
  }
}

`;


const IndexPage = () => {




  return (
    <TemplatePage1
      title={"¿What is quality in Health Care?"}
      bg={"var(--tema4)"}
      tc={"white"}
      adorno={<Adorno/>}
      hero={true}
      content={(
        <Wrapper >
          <p>The WHO defines it as "The grade in which the health services are offered to people and groups of people in order for them to increase the probability to achieve their desired sanitarean results and it adjust to professional knowledge based on probatory data. One quality health attention with satisfactory standards in the following dimensions:</p>
          <CardContainer>
            <Card>
              <img src={img3} alt={img3}/>
              <h1>EFFICACY</h1>
              <p>Research based attention</p>
            </Card>
            <Card>
            <img src={img5} alt={img5}/>
              <h1>Opportunity</h1>
              <p>Attention that reduce waiting times</p>
            </Card>
            <Card>
            <img src={img4} alt={img4}/>
              <h1>SECURITY</h1>
              <p>Attention that reduces the risk in patients</p>
            </Card>
            <Card>
            <img src={img2} alt={img2}/>
              <h1>Equitative</h1>
              <p>Attention that doesn change through age, sex, gender</p>
            </Card>
            <Card>
            <img src={img6} alt={img6}/>
              <h1>Person Centered</h1>
              <p>Atención adeaqueate to preferences, necessities, etc</p>
            </Card>
            <Card>
            <img src={img1} alt={img1}/>
              <h1>EFFICIENCY</h1>
              <p>Attention that maximies the benefits of the available resources</p>
            </Card>
          </CardContainer>
          <p>
          The quality of attention in health should be measured in order to improve continiously. For that reason, the health care given should be based on relevant data that are considers the necessities and the preference of the users, the families and the communities.
          </p>
        </Wrapper >
      )}
    />
  )
};

export default IndexPage






const Adorno = styled.div`
position: absolute;
bottom: 0px;
right: 0;
height:100%;
@media only screen and (min-width:768px){
  bottom: 0px;
right: 400px;
height:100%;
  width: 800px;
  background-position-x:0%;
  background-image: url(${adorno});
background-size: contain;
background-repeat: no-repeat;
}
@media only screen and (min-width:1200px){
  bottom: 0px;
left:0;
right:auto;
height:100%;
  width: 600px;
  background-position-x:0%;
  background-image: url(${adorno});
background-size: contain;
background-repeat: no-repeat;
}
`;